@use '@angular/material' as mat;

@include mat.core();

$cyan-primary: mat.get-color-from-palette(mat.$cyan-palette, 100);
$cyan-accent: mat.get-color-from-palette(mat.$teal-palette, 800);
$cyan-theme: mat.define-light-theme((
    color: (
      primary: $cyan-primary,
      accent: $cyan-accent
    ),
    density: 0
  ));


