@use '@angular/material' as mat;
@include mat.core();

@import './themes/default.scss';
@import './themes/purple.scss';
@import './themes/pink.scss';
@import './themes/deep-orange.scss';
@import './themes/alternative.scss';
@import './themes/cyan.scss';
@import './themes/deepPurple-amber.scss';
@import './themes/indigo-pink.scss';
@import './themes/pink-bluegray.scss';
@import './themes/purple-green.scss';
@import 'ngx-bar-rating/themes/br-default-theme';
@import './themes/datetimepicker.scss';

@import '~quill/dist/quill.snow.css';
@import '@webdatarocks/webdatarocks/webdatarocks.min.css';

@include mat.all-component-themes($my-condensed-theme);

@include mat.button-theme($my-theme);

@include mat.form-field-theme($my-condensed-theme2);

@include mat.progress-bar-theme($my-progress-theme);

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Google Sans", Roboto, "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  background-color: #F6F6F6;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: none;
  --mat-mdc-button-ripple-color: none;
}

.image-uploading {
  position: relative;
  display: inline-block;
}

.image-uploading img {
  max-width: 98% !important;
  filter: blur(5px);
  opacity: 0.3;
}

.image-uploading::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #1e986c;
  z-index: 1;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.a {
  font-size: 14px;
  width: 159px;
  height: 16px;
  font-family: Roboto;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.07px;
  color: #1a284d;
}

a {
  text-decoration: none;
}

button[mat-mini-fab] {
  transform: scale(0.5) !important;
}

.mini-fab {
  transform: scale(0.6) !important;
}

.simple-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.h1, h1 {
  color: #09215c;
  font-size: 3.5rem;
  font-weight: 400;
}

.h2, h2 {
  color: #09215c !important;
  font-size: 1.5rem !important;
  font-weight: 400;
}

.h3, h3 {
  font-size: 1rem !important;
}

.mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
  padding: 0.4em 0px;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f2f2f2;
  padding: 0 5px 0 15px;
  border-radius: 8px;
}

.dialog-close-icon {
  font-size: 20px;
}

.search-close-btn {
  height: 36px !important;
}

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.block-display {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.dashboard-card {
  padding: 5px;
}

.dashboard-template-content {
  width: 500px;
  // height: 390px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px 20px 20px 10px;
}


.column-flex {
  display: flex;
  flex-direction: column;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.row-flex-flow {
  display: flex;
  flex-flow: row;
}

.column-flex-flow {
  display: flex;
  flex-flow: column;
}

.alItems-flex-start {
  align-items: flex-start;
}

.align-start-flex {
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.align-end-flex {
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-stretch-column {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.flex-stretch-row {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.align-start-end-flex {
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end;
}

.align-center-flex {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.align-center-start-flex {
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
}

.align-start-center-flex {
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.align-space-between-top-flex {
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
}

.align-center-center-flex {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.flex-space-btwn {
  display: flex;
  justify-content: space-between;
}

.flex-spaceBtwn-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auto-flex {
  flex: 1 1 auto;
}

.fxFlex-25 {
  flex: 1 1 25%;
}

.fxFlex-90 {
  flex: 1 1 95%;
}

.fxFlex-100 {
  flex: 1 1 100%;
}

.flow-flex {
  display: flex;
  flex-flow: row wrap;
}

.flex-flow-wrap {
  display: flex;
  flex-flow: wrap;
}

.flex-alignItems-center {
  display: flex;
  align-items: center;
}

.flex-flow-col {
  display: flex;
  flex-flow: column;
}

.fill-flex {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.flex-noshrink {
  flex: 1 0 auto;
}

.flex-row-wrap {
  flex-flow: row wrap;
  display: flex;
}

.flex-col-wrap {
  flex-flow: column wrap;
  display: flex;
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
}

.standard-border {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.mat-mdc-dialog-surface {
  padding: 24px !important;
}

.mdc-data-table__header-cell {
  font-size: 0.75rem;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  font-size: 0.875rem;
}

.mat-mdc-form-field-icon-prefix > .mat-icon {
  padding: 0px 0px 0px 4px !important;
}


.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0px 4px 0px 0px !important;
}

.mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
  margin: 0 5px 0 0;
}

.mat-mdc-form-field-label-wrapper {
  top: -1.5em;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 6px;
}

.password-field .mat-mdc-form-field-subscript-wrapper {
  width: 100%;
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 0em;
  line-height: 1.15;
}

.mat-badge.hide-text .mat-badge-content {
  color: transparent;
}

.timepicker-backdrop-overlay {
  z-index: 1000 !important;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.optionRow {
  display: flex;
  align-items: center;
  align-content: center;
}

.fieldRow {
  display: flex;
  align-content: center;
  align-items: center;
}

.fieldRowWithPadding {
  display: flex;
  align-content: center;
  align-items: flex-start;
}

.question-icon {
  color: #a3a3a3;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
}

.fieldRow .mat-mdc-form-field {
  flex-direction: unset !important;
}

.cardRows {
  display: flex;
  align-content: initial;
  flex-wrap: wrap;
}

.wrapFlex {
  display: flex;
  flex-wrap: wrap;
}

.controlList {
  margin: 2px 0px 2px 0px !important;
}

.longField {
  width: 400px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-size: 0.875rem !important;
}

.cursor-pt {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.clearBoth {
  clear: both;
}

.leftFloat {
  float: left;
}

.rightFloat {
  float: right;
}

.noDisplay {
  display: none;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.sub-title {
    font-size: 0.8em;
}

.custom-scroll-bar {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 5px !important;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.26);
}

.txt-align-right {
  text-align: right;
}

.txt-align-center {
  text-align: center;
}

.txt-align-left {
  text-align: left;
}

.vert-align-top {
  vertical-align: top;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

.justify-flex-end {
  justify-content: flex-end !important;
}

.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height: 100%;
}

.height-4 {
  height: 4px;
}

.height-auto {
  height: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.font-wt-400 {
  font-weight: 400 !important;
}

.font-wt-500 {
  font-weight: 500 !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.wd-4-per {
  width: 4% !important;
}

.wd-95-per {
  width: 95%;
}

.wd-15-per {
  width: 15% !important;
}

.wd-20-per {
  width: 20% !important;
}

.wd-25-per {
  width: 25% !important;
}

.wd-30-per {
  width: 30% !important;
}

.wd-40-per {
  width: 40% !important;
}

.wd-45-per {
  width: 45% !important;
}

.wd-49-per {
  width: 49% !important;
}

.wd-80-per {
  width: 80% !important;
}

.wd-90-per {
  width: 90% !important;
}

.wd-70-per {
  width: 70% !important;
}

.wd-75-per {
  width: 75% !important;
}

.halfWidth {
  width: 50%;
}

.threequarterWidth {
  width: 75%;
}

.width-15 {
  width: 15px;
}

.width-30 {
  width: 30px;
}

.width-100 {
  width: 100px;
}

.width-120 {
  width: 120px;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-15 {
  margin: 15px;
}

.margin-20 {
  margin: 20px;
}

.auto-margin {
  margin: auto;
}

.margin-0 {
  margin: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.boxSizing-borderBox {
  box-sizing: border-box;
}

.margin-top-bot-10 {
  margin: 10px 0 !important;
}

.margin-top-bot-5 {
  margin: 5px 0 !important;
}

.margin-top-bot-40 {
  margin: 40px 0 !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-10 {
  padding: 10px;
  overflow: auto;
}

.padding-20 {
  padding: 20px;
}

.padding-0 {
  padding: 0px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.padding-lr-10 {
  padding: 0 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-30 {
  padding-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-mr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.widthThirty {
  width: 30%;
}

.hidden-visibility {
  visibility: hidden;
}

.color-black {
  color: black !important;
}

.loading-container {
  margin: 10px;
}

.loading-content {
  float: left;
  height: 25px;
  width: 175px;
}

.spinner-div {
  float: left;
}

.spinner-text {
  margin-left: 10px;
  float: left;
}

.sidenav-container {
  width: 100%;
  height: 100vh;
  overflow: unset !important;
}

.sidenav-qe-container {
  width: 100%;
  height: 100%;
}

.sidenav-content {
  // height: 100%;
  background-color: #f6f6f6;
  overflow: unset !important;
}

.whtSpc-noWrap {
  white-space: nowrap;
}

.or-separator {
  margin: 20px 0 10px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.or-separator i {
  padding: 0 10px;
  background: #ffffff;
  position: relative;
  top: -11px;
  z-index: 1;
}

.or-separator span {
  padding: 0 10px;
  background: #f6f6f6;
  position: relative;
  top: -11px;
  z-index: 1;
}

.file-select-button, .file-name {
  display: inline-block;
  margin: 5px;
  word-break: break-all;
}

.titlePanel-div {
  float: left;
  font-weight: 500 !important;
}

.spacer {
  flex: 1 1 auto;
}

.terms-privacy-h3-color {
  color: #62a8ff;
}

.list-style-none {
  list-style-type: none;
}

//----- Questions Component Start ----------------------------------------------

.add-qn-question-div {
  cursor: pointer;
  margin: 0 0 20px 30px;
  width: 235px;
  text-decoration: none;
}

.add-qn-question-card {
  height: 160px;
}

.add-qn-question-card mat-card-header {
  display: flex;
  justify-content: space-evenly;
}

.add-qn-question-card:hover {
  background: rgba(0,0,0,.04);
}

.add-qn-list-container {
  line-height: 16px;
  height: 50px;
  overflow: hidden;
}

.add-qn-grid-container {
  line-height: 16px;
  height: 95px;
  overflow: hidden;
}

.add-qn-show {
  overflow: visible;
  height: auto;
}

.add-qn-library-list-container {
  padding: 5px 10px 0 0;
  height: 60vh;
}

.add-qn-library-list-content {
  margin: 8px 0 8px 2px;
  cursor: pointer;
}

.add-qn-list-questionTitle-div {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}

.add-qn-detail-questionTitle-div {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.add-qn-detail-question-title {
  font-size: 18px;
}

.adv-ans-opt-row {
  display: flex;
  align-items: initial;
  align-content: initial;
  width: 100%;
}

.adv-ans-opt-container {
  width: 100%;
  display: flex;
  align-items: start;
  align-content: center;
}

.adv-ans-opt-close-icon-button {
  position: relative;
  top: 4px;
  padding: 0 !important;
  width: 20px;
  height: 20px;
}

.adv-ans-opt-close-icon {
  font-size: 12px;
}

.adv-ans-opt-dispAnsOpt-field {
  width: 75px;
}

.adv-ans-opt-dispFactor-field {
  width: 150px;
}

.adv-prop-ruleLayout {
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 10px;
}

.adv-prop-skipLogic-div {
  padding: 2px 10px 2px 10px;
}

.adv-routing-condition-field {
  max-width: 130px;
}

.adv-routing-embed-field {
  max-width: 115px;
}

.adv-routing-question-field {
  max-width: 170px;
}

.adv-routing-dropDownArrow-btn {
  height: 35px;
  margin-top: -5px;
}

.adv-routing-andOr-field {
  max-width: 70px;
  margin: 0;
}

.adv-routing-if-div {
  padding: 0 30px;
}

.ans-option-title {
  font-size: 0.75em;
}

.ans-option-small-icon {
  font-size: 14px;
  margin-top: 16px;
}

.advancedOptions {
  display: flex;
  align-items: initial;
  align-content: initial;
}

.ans-option-toolbar {
  height: 35px !important;
  background: #fafafa;
}

.ans-option-advOpt-div {
  flex-grow: 1;
}

.ans-option-addBtn-div {
  flex-grow: 0;
}

.embed-data-container {
  width: 100%;
  max-height: 35vh;
}

.embed-data-option-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0 0 0;
}

.embed-data-src-field {
  max-width: 145px;
}

.embed-data-dataType-field {
  max-width: 135px;
}

.embed-data-val-field {
  max-width: 140px;
}

.embed-data-surMetaData-field {
  max-width: 155px;
}

.embed-data-qn-field {
  max-width: 115px;
}

.embed-data-element-field {
  max-width: 110px;
}

.imageMap-options {
  display: flex;
  justify-content: space-evenly;
}

.imageMap-options-handset {
  display: flex;
  flex-direction: column;
}

.qe-block-qnActionBtns {
  float: right;
  writing-mode: vertical-lr;
}

#blockContentInside {
  width: 100%;
  margin: 5px;
}

.qe-block-separator {
  margin: 20px 0 10px;
  text-align: center;
  border-top: 5px solid #ccc;
}

.qe-block-separator i {
  padding: 0 10px;
  background: #ffffff;
  position: relative;
  top: -11px;
  z-index: 1;
}

.qe-block-addQnBtn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.randomise-embed-option-div {
  display: flex;
  align-items: center;
}

.randomise-dialog-container {
  width: 100%;
  max-height: 35vh;
}

.randomise-dialog-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #cce5ff;
  margin: 0;
  padding-top: 5px;
}

.randomise-qnSelector-field {
  max-width: 105px;
}

.rating-else-route-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.routing-dialog-content {
  display: block;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: 35vh !important;
  background-color: #fafafa;
}

.send-sms-dest-div {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  width: 85%;
}

.send-sms-dest-icon {
  color: #ccc;
  text-align: right;
}

.survey-flow-ruleLayout {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 5px 10px 5px 10px;
}

.survey-flow-editRouting {
  border: 1px solid #b6d4fe;
  border-radius: 6px;
  background-color: #cfe2ff;
  padding-left: 10px;
  margin: 10px 0;
}

.quota-edit {
  border: 1px solid #b6d4fe;
  border-radius: 6px;
  background-color: #cfe2ff;
  margin: 20px 20px;
  padding: 0px 10px;
}

.survey-flow-qn-icon {
  font-size: 20px;
  margin-top: 7px;
}

.survey-flow-delete-icon-div {
  margin-top: -21px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.question-separator {
  margin: 20px 0 10px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.question-var-container {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.question-answer-options-div {
  margin: 10px 0;
  display: flex;
  width: 90%;
}

.question-type-icon-div {
  margin-top: 8px;
}

.question-copy-btn-div {
  width: 28px;
}

.qns-noquestions-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;
}

.qns-noquestions {
  display: flex;
  justify-content: center;
  word-wrap: normal;
  align-items: center;
  height: 500px;
  width: 445px;
  background-image: url(assets/img/blankeditor.png);
  background-repeat: no-repeat;
}

.qns-noquestions-span {
  color: #a8a8a8;
}

//----- Questions Component End ------------------------------------------------

//----- Question Properties Component Start ------------------------------------

.qn-properties-window {
  padding: 5px 0 0 10px;
  width: 100%;
  height: 63.5vh !important;
}

//----- Question Properties Component End --------------------------------------

//----- Questionnaire Sidebar Component Start ----------------------------------

.questionnaire-sidebar-list {
  height: 66vh !important;
  width: 300px;
  padding: 0px;
  max-width: 100%;
  border-top: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  overflow: hidden;
}

.questionnaire-sidebar-box {
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.questionnaire-sidebar-notSelected {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
  background: white;
}

.questionnaire-sidebar-selected {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
  background: #cce5ff;
}

.questionnaire-sidebar-hide {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: move;
  display: none;
}

.questionnaire-sidebar-notSelected:hover {
  .questionnaire-sidebar-hide {
    display: block !important;
  }
}

.questionnaire-sidebar-selected:hover {
  .questionnaire-sidebar-hide {
    display: block !important;
  }
}

.questionnaire-sidebar-box:last-child {
  border-bottom: solid 1px #ccc;
}

.questionnaire-sidebar-list.cdk-drop-list-dragging .questionnaire-sidebar-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.questionnaire-sidebar-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.questionnaire-sidebar-text {
  padding: 0 4px;
  width: 99%;
  float: left;
}

//----- Questionnaire Sidebar Component End ------------------------------------

//----- Question Library Component Start ---------------------------------------

.qLib-question-sidebar-list {
  height: 66vh !important;
  width: 300px;
  padding: 0px;
  max-width: 100%;
  border-top: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  overflow: hidden;
}

.qLib-question-sidebar-box {
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  display: flex;
  padding: 10px 5px;
}

.qLib-question-sidebar-box:last-child {
  border-bottom: solid 1px #ccc;
}

.qLib-question-sidebar-text {
  padding: 0 4px;
  width: 100%;
  float: left;
}

.qLib-icon {
  color: #a3a3a3;
}

.qLib-add-del-btn-div {
  display: flex;
  justify-content: space-evenly;
}

//----- Question Library Component End -----------------------------------------

//----- Question Editor Component Start ----------------------------------------

.qe-link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.qe-sidenav-left {
  width: 280px;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  background-color: #ffffff !important;
  border: 1px solid #ccc;
}

.qe-sidenav-right {
  width: 280px;
  border-left: solid 1px #ccc;
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
}

.qe-sidenav-content {
  height: 100%;
}

@media (min-width: 768px) {
  .qe-sidenav-content {
    height: 73vh !important;
    margin: 5px 0 5px 0;
  }
}

.qe-question-container {
  width: 100%;
}

@media (min-width: 768px) {
  .qe-question-container {
    width: 100%;
    padding: 0 20px;
  }
}

.qe-sidenav-left-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qe-sidenav-left-heading {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
}

.qe-sidenav-right-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.qe-sidenav-right-heading {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  font-weight: 500;
}

.qe-survey-flow-div {
  height: 68vh !important;
  overflow: auto !important;
}

.qe-survey-flow-content {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: center;
}

.qe-survey-prvw-height {
  height: 63vh !important;
}

.qe-prop-close-icon {
  font-size: 20px;
  height: 25px;
}

.qe-survey-sett-bottom-border {
  border-bottom: 1px solid #ccc;
}

.qe-survey-style-div {
  height: 62vh !important;
  padding-bottom: 2px;
}

.qe-sidemenu-div {
  width: 40px;
  padding: 0px 0px;
  border: solid 1px #ccc;
  position: sticky;
  height: 328px;
}

//----- Question Editor Component End ------------------------------------------

//----- Question Action Button Component Start ---------------------------------

.question-action-vert-button {
  padding: 1px 0px 1px 10px;
  min-width: unset !important;
}

.question-action-menu {
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 0;
  width: 50px;
}

//----- Question Action Button Component End -----------------------------------

//----- Panel Selector Template Component Start --------------------------------

.panel-slctr-template-cards {
  height: 270px;
}

.panel-slctr-template-cards:hover {
  background: rgba(0,0,0,.04);
}

.panel-slctr-template-cards-image {
  height: 110px;
}

.panel-slctr-template-heading-div {
  color: #19284c;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  margin: 10px 0;
}

.panel-slctr-template-cards-div {
  margin: 20px;
  width: 200px;
  text-decoration: none;
  cursor: pointer;
}

.panel-slctr-template-card-name-div {
  color: #19284c;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
}

.panel-slctr-template-card-remark-div {
  color: #6d6d6d;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
}

.panel-slctr-template-card-desc-div {
  color: #6d6d6d;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
}

//----- Panel Selector Template Component End ----------------------------------

//----- Panel Selector Component Start -----------------------------------------

.audience-useRanges-content {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.cat-list-selList {
  padding: 0;
  margin: 0;
  background-color: #ffffff !important;
}

.cat-list-qnList-optionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.cat-list-panel-header {
  font-size: 14px;
  height: 30px !important;
  padding: 0 0 0 12px;
}

.cat-list-container {
  float: left;
  width: 19%;
  height: 52.7vh;
  overflow: auto;
  padding: 0;
  border-right: 1px solid #ccc;
}

.panel-selector-main-div {
  width: 100%;
  height: 60vh;
}

.panel-selector-separator {
  margin: 20px 0 0px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.panel-selector-separator b {
  padding: 0 10px;
  background: #ffffff;
  position: relative;
  top: -11px;
  z-index: 1;
}

.panel-selector-countrySelector-div {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 5px 8px;
  background: #ffffff;
}

.panel-selector-quota-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-selector-bottom-fields-div {
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ffffff;
  padding: 8px;
}

.panel-selector-audience-container {
  float: left;
  width: 19%;
  height: 54vh;
  background: #ffffff;
}

.panel-selector-audience-heading-div {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 7%;
  font-weight: 500;
}

.panel-selector-audience-div {
  overflow: auto;
  height: 91%;
  padding: 5px;
}

.panel-selector-catSelector-container {
  float: left;
  padding: 15px 50px;
  width: 62%;
  height: 52.7vh;
  overflow: auto;
  background: #fafafa;
  border-right: 1px solid #ccc;
}

//----- Panel Selector Component End -------------------------------------------

//----- Panels Component Start -------------------------------------------------

.panel-summ-selectedPanel-span {
  color: #19284c;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  margin: 10px 0;
}

.panel-summ-panelName-span {
  color: #62a8fe;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  margin: 10px 0;
}

.panels-newRespondentTemplate-btn {
  width: 195px;
  background-color: #19284c !important;
  color: #ffffff;
  font-weight: 500;
  border-radius: 4px;
  margin: 0px 0 0 0;
}

.panels-element-detail {
  overflow: hidden;
  max-height: 150px;
  display: flex;
  justify-content: center;
  background: #efefef;
}

tr.panels-detail-row {
  height: 0 !important;
}

.panels-headingText-div {
  align-items: center;
  margin: 5px;
  color: #6d6d6d;
}

.panels-addResp-search-div {
  display: flex;
  margin: 5px;
  justify-content: flex-start;
  color: #6d6d6d;
}

//----- Panels Component End ---------------------------------------------------

//----- Organisation Component Start -------------------------------------------

.fav-org-drag-div {
  width: 200px;
  height: 40px;
  border: 1px dotted black;
}

.fav-org-card {
  width: 200px;
  margin: 20px;
  padding: 10px;
}

//----- Organisation Component End ---------------------------------------------

//----- Network Component Start ------------------------------------------------

.mng-multiRqst-sidebar-list {
  height: 66vh;
  width: 300px;
  padding: 0px;
  max-width: 100%;
  min-height: 60px;
  display: block;
  overflow: hidden;
  border: solid 1px #ccc;
}

.mng-multiRqst-sidebar-box {
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.netwrk-dtl-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.netwrk-dtl-content {
  width: 750px;
  display: flex;
  flex-flow: column;
}

//----- Network Component End --------------------------------------------------

//----- Mat Confirm Dialog Component Start -------------------------------------

.mat-confirm-dialog-button-row button,
.mat-confirm-dialog-button-row a {
  margin-right: 8px;
}

.mat-confirm-dialog-span {
  font-weight: 400;
  font-size: 16px;
}

.mat-confirm-dialog-bottom-div {
  height: 50px;
}

//----- Mat Confirm Dialog Component End ---------------------------------------

//----- Lib Component Start ----------------------------------------------------

.boolean-radio-button-label {
  font-weight: normal !important;
}

.bucket-list {
  width: 90%;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 40px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 0 15px 0;
}

.bucket-box {
  padding: 10px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.bucket-box:last-child {
  border: none;
}

.bucket-list.cdk-drop-list-dragging .bucket-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.bucket-group-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-lang-div {
  display: flex;
  justify-content: flex-end;
}

.category-radio-button-label {
  font-weight: normal !important;
  font-size: 14px;
}

.data-error-label {
  color: red !important;
}

::ng-deep div.date-time-input-field > mat-form-field > div.mat-mdc-text-field-wrapper {
  height: 36px !important;
}

::ng-deep div.date-time-input-field > mat-form-field > .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
}


.float-star-min-label {
  position: relative;
  bottom: 10px;
  left: 25px;
}

.float-star-max-label {
  position: relative;
  bottom: 10px;
  right: 25px;
}

.float-radio-min-label {
  position: relative;
  bottom: 22px;
  left: 25px;
}

.float-radio-max-label {
  position: relative;
  bottom: 22px;
  right: 32px;
}

::ng-deep .float-star-rating-vertical .br {
  margin: 0 !important;
  position: relative;
}

::ng-deep .float-star-rating-vertical .br-units {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column !important;
}

::ng-deep .float-star-rating-vertical .br-default .br-units {
  align-items: start !important;
}

.float-slider-hr-div {
  width: 100%;
  padding: 0 18px;
  overflow-x: hidden;
}

.float-slider-hr-maxMinString-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.float-slider-vert-div {
  height: 250px;
  width: 245px;
  padding: 20px;
  transform: rotate(270deg);
}

.float-slider-vert-maxMinString-div {
  display: flex;
  justify-content: space-between;
}

.float-vert-slider-content {
  display: flex;
  width: 215px;
}

.float-rotate-90 {
  transform: rotate(90deg);
}

.geocode-id-div {
  width: 100%;
  height: 200px;
}

.loop-categorical-catColLabels {
  width: 7vw;
  margin-bottom: 10px;
  min-width: 92px;
}

.loop-categorical-txtboxColLabels {
  width: 17vw;
  text-align: center;
  margin-bottom: 10px;
}

.loop-categorical-cellLabel {
  width: 25vw;
  display: flex;
  margin: 0 5px 20px;
}

@media only screen and (min-width: 300px) and (max-width:480px) {
  .loop-categorical-catColLabels {
    width: 22vw;
    padding: 0 3px !important;
  }

  .loop-categorical-cellLabel {
    width: 30vw;
    justify-content: flex-start;
    margin: 0 0px 20px;
  }
}

.loop-categorical-main-div {
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-around;
}

.loop-categorical-cellLabel-div {
  display: flex;
  justify-content: center;
}

.loop-categorical-data-div {
  padding: 0 0 0 10px;
}

.loop-categorical-data {
  margin: 0 0 10px;
}

.loop-categorical-category {
  display: flex;
  width: 5vw;
  height: 35px;
  align-items: flex-end;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.loop-categorical-validAns-icon-div {
  display: flex;
  align-items: center;
  padding: 5px;
}

.loop-categorical-validAns-icon {
  color: green;
}

.ranking-example-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.ranking-example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  font-size: 14px;
}

.ranking-example-box:last-child {
  border: none;
}

.ranking-example-list.cdk-drop-list-dragging .ranking-example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ranking-drag-handle {
  position: relative;
  right: 10px;
  color: #ccc;
  width: 24px;
  height: 24px;
  cursor: move;
}

.onescript-playr-main-content {
  display: block;
  overflow: auto;
}

.onescript-playr-main-content-preview {
  margin: 15px;
  display: block;
}

.poweredBy {
  text-align: center;
  font-size: 10px;
  width: 100%;
  color: #000000;
}

.poweredByLight {
  text-align: center;
  font-size: 10px;
  width: 100%;
  color: #ffffff;
}

.poweredByImageLight {
  width: 100px;
}

.onescript-playr-poweredByVersion {
  font-size: 8px;
}

.onescript-playr-poweredByImage {
  width: 100px;
}

.onescript-playr-logo {
  width: 186px;
  height: 48px;
  object-fit: contain;
  margin: 5px;
}

.onescript-playr-header-div {
  height: 60px;
  overflow: auto;
}

//----- Lib Component End ------------------------------------------------------

//----- Languages Component Start ----------------------------------------------

.lang-edtr-advancedOptionRow {
  display: flex;
  align-items: initial;
  align-content: initial;
  width: 100%;
}

.lang-edtr-table-container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 65vh;
}

.lang-edtr-missLang-top-padding {
  padding-top: 32px;
}

.lang-edtr-missLang-msg-div {
  height: 30px;
}

.lang-summ-table-div {
  height: 65vh;
}

//----- Languages Component End ------------------------------------------------

//----- Invite People Component Start ------------------------------------------

.invite-ppl-un-div {
  margin-top: 7px !important;
}

//----- Invite People Component End --------------------------------------------

//----- Invite Layout Component Start ------------------------------------------

.invite-layout-content {
  background-color: #f6f6f6 !important;
}

//----- Invite Layout Component End --------------------------------------------

//----- Interview Test Component Start -----------------------------------------

.intrvw-test-response-field {
  width: 80px;
}

//----- Interview Test Component End -------------------------------------------

//----- Interview Preview Component Start --------------------------------------

.runBy-qrCode-dialog-content {
  padding: 0 25px;
}

.intrvw-prvw-preview {
  margin-top: 5px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
}

.intrvw-prvw-messageWindow {
  margin-top: 5px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  height: 45vh;
  width: 375px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.intrvw-prvw-messageWindow::-webkit-scrollbar {
  width: 5px;
}

.intrvw-prvw-messageWindow::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.26);
}

.intrvw-prvw-imessage {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.intrvw-prvw-imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
}

.intrvw-prvw-imessage p::before,
.intrvw-prvw-imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.intrvw-prvw-messageDate {
  text-align: center;
  width: 100%;
  padding: 0.1rem .1rem;
}

p.intrvw-prvw-from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}

p.intrvw-prvw-from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.intrvw-prvw-from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="intrvw-prvw-from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.intrvw-prvw-from-me ~ p.intrvw-prvw-from-me {
  margin: 0.25rem 0 0;
}

p.intrvw-prvw-from-me ~ p.intrvw-prvw-from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.intrvw-prvw-from-me ~ p.intrvw-prvw-from-me:last-child {
  margin-bottom: 0.5rem;
}

p.intrvw-prvw-from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

p.intrvw-prvw-from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.intrvw-prvw-from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="intrvw-prvw-from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^="intrvw-prvw-from-"].emoji::before {
  content: none;
}

.intrvw-prvw-dlg-content {
  width: 100%;
  height: 95vh;
  overflow: hidden !important;
}

.intrvw-prvw-github-img {
  width: 16px;
}

.intrvw-prvw-json-string {
  height: 100px;
  overflow-y: scroll;
}

.intrvw-prvw-onescript-errorDtl {
  height: 100px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.intrvw-prvw-player-div {
  height: 55vh;
}

.intrvw-prvw-player-container {
  height: 50vh;
}

.intrvw-prvw-txtMsg-div {
  width: 375px;
}

//----- Image Cropper Component End --------------------------------------------

//----- Image Cropper Component Start ------------------------------------------

.img-cropper-div {
  width: 250px;
  height: 200px;
  float: left;
}

.img-cropper-imgIcon {
  margin: 0 0px 3px 0;
}

.img-cropper-croppedImg {
  float: left;
  margin: 10px;
  max-height: 120px;
}

//----- Image Cropper Component End --------------------------------------------

//----- Help Component Start ---------------------------------------------------

.help-main-div mat-card {
  padding: 0px;
}

.help-toolbar {
  color: #19284c;
  justify-content: center;
  font-weight: 600;
  background-color: #ffffff;
  letter-spacing: 1px;
}

.help-desc-txtArea {
  height: 150px;
}

.help-submit-btn {
  background-color: #19284c;
  color: #ffffff;
  width: 80%;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
}

//----- Help Component End -----------------------------------------------------

//----- Forgot Password Component Start ----------------------------------------

.forgot-pwd-main-div {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.forgot-pwd-card {
  margin: 100px auto;
  padding: 10px;
}

.forgot-pwd-form {
  padding: 20px;
}

.forgot-pwd-email-div {
  width: 80%;
  padding-right: 10px;
}

//----- Forgot Password Component End ------------------------------------------

//----- Find User Component Start ----------------------------------------------
.find-user-main-div mat-card {
  width: 100%;
}
//----- Find User Component End ------------------------------------------------

//----- File Upload Component Start --------------------------------------------

.file-status-table-container {
  position: relative;
  height: 60vh;
  overflow: auto;
}

tr.file-status-detail-row {
  height: 0;
}

tr.file-status-element-row:not(.file-status-expanded-row):hover {
  background: whitesmoke;
}

tr.file-status-element-row:not(.file-status-expanded-row):active {
  background: #efefef;
}

.file-status-element-row td {
  border-bottom: 1px solid #ccc;
}

.file-status-element-detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.file-status-element-description {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

//----- File Upload Component End ----------------------------------------------

//----- Feature Tour Component Start -------------------------------------------

.feature-tour-logo {
  width: 160px;
  height: 40px;
  object-fit: contain;
}

.tour-start-btn {
  color: #ffffff;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background-color: #62a8ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.07px;
}

.whatsnew-start-btn {
  color: #ffffff;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background-color: #62a8ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.07px;
}

.dialog-close-btn {
  color: #ffffff;
  width: 80px;
  height: 30px;
  border-radius: 4px;
  background-color: #62a8ff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.07px;
}

@media (max-width: 600px) {
  .tour-start-btn {
    width: 70px;
    font-size: 13px;
  }

  .whatsnew-start-btn {
    width: 70px;
    font-size: 13px;
  }

  .dialog-close-btn {
    width: 70px;
    font-size: 13px;
  }
}

.feature-tour-content-div {
  background-color: #cbe3fe;
  padding: 20px;
}

.start-tour-content-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.start-tour-content {
  display: flex;
  width: 70%;
}

.tour-icon-div {
  width: 20%;
  display: flex;
  align-items: center;
}

.tour-icon {
  font-size: 24px;
}

.start-tour-content-span {
  font-size: 16px;
  font-weight: 500;
  width: 80%;
}

.whatsnew-content-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whatsnew-content {
  display: flex;
  width: 70%;
  align-items: center;
}

.feature-tour-closeBtn-div {
  justify-content: center;
}

//----- Feature Tour Component End ---------------------------------------------

//----- Edit Panel Component Start ---------------------------------------------

.panel-pricing-editPanel-container {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  width: 50%;
  height: 200px;
}

.panel-pricing-header-div {
  align-items: center;
  width: 100%;
  padding: 1em;
}

.panel-pricing-congratsMsg-div {
  width: 50%;
  margin: 10px;
  height: 200px;
  text-align: left;
}

.table-mobile {
  display: table !important;
}

.table-mobile .row {
  display: table-row !important;
}

.table-mobile .cell {
  display: table-cell;
}

.message {
}

.values {
  width: 50px;
  text-align: right;
}

.pricing-card {
  padding: 10px 25px;
}

.pricing-summ-rate-field {
  width: 60px;
}

.pricing-summ-respondents-field {
  width: 75px;
}

.pricing-summ-audience-container {
  overflow: auto;
  max-height: 200px;
  width: 100%;
  padding: 5px;
}

//----- Edit Panel Component End -----------------------------------------------

//----- Development Component Start --------------------------------------------

.metadata-table-container {
  padding: 0 0 0 15px !important;
}

.metadata-table-type-field {
  width: 90px !important;
}

.metadata-table-list {
  display: flex !important;
}

.metadata-table-list > * {
  flex: 2 1 2 1 1 1px;
}


.sms-edtr-icon-small {
  font-size: 14px;
  height: 16px;
  width: 16px;
  padding-top: 4px;
}

.sms-edtr-warning {
  margin-left: 2px;
  font-size: 0.75em;
}

.sms-edtr-field {
  font-size: 0.875rem;
  width: 100%;
  border: none;
}

.sms-edtr-field:focus {
  outline: none;
}

.sms-edtr-field, ::after, ::before {
  border: none;
}

.sms-edtr-toolbar-top {
  height: 30px !important;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.sms-edtr-toolbar-bottom {
  height: 30px !important;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  width: 100%;
}

.wbHook-det-individual-card {
  width: 400px !important;
  margin: 5px 5px 5px 0px !important;
}

//----- Development Component End ----------------------------------------------

//----- Delivery Summary Component Start ---------------------------------------

.dlvry-summ-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.dlvry-summ-stat-card {
  padding: 20px;
}

.dlvry-summ-hrChrt-container {
  padding: 10px 0;
}

.dlvry-summ-statCards-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 20px;
}

//----- Delivery Summary Component End -----------------------------------------

//----- Data Analysis Component Start ------------------------------------------

.basic-data-header-container {
  height: 60px;
  line-height: 20px !important;
  overflow: hidden;
}

.basic-data-header-span {
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  white-space: pre-wrap !important;
}

.basic-data-response-label-container {
  overflow: hidden;
}

.basic-data-response-span {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  display: inline-block !important;
  width: 100px;
}

.basic-data-table-header {
  text-align: center !important;
  padding-left: 2px;
  padding-right: 2px;
}

tr.conf-proc-detail-row {
  height: 0;
}

.fieldSlctr-pipe-icon-button {
  position: relative !important;
  top: 4px;
  padding: 0 !important;
}

.dataIO-automatedIcon {
  top: 0px;
  left: 37px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  height: 15px;
  width: 15px;
  font-size: 12px;
  z-index: 100;
}

tr.dataIO-element-row:not(.dataIO-expanded-row):hover {
  background: whitesmoke;
}

tr.dataIO-element-row:not(.dataIO-expanded-row):active {
  background: #efefef;
}

tr.dataIO-element-row.dataIO-expanded-row:active {
  background: #efefef;
}

.dataIO-element-detail {
  display: flex;
  background: #efefef;
}

tr.dataIO-detail-row {
  height: 0;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
}

.dataIO-upld-content {
  display: flex;
  flex-flow: row;
  margin: 5px;
}

.dataIO-schedule-div {
  width: 25px;
  align-content: center;
  vertical-align: middle;
}

.dataIO-upld-icon-div {
  width: 30px;
  height: 35px;
  align-content: center;
  vertical-align: middle;
}

.dataIO-upld-icon {
  font-size: 40px !important;
  width: 70px !important;
  height: 70px !important;
}

.exprt-autmn-content {
  height: 68vh;
  overflow: auto !important;
}

.exprt-filtr-content {
  padding: 2px 10px 2px 10px;
}

.ftp-setup-host-field {
  width: 89%;
}

.ftp-setup-port-field {
  width: 9%;
}

.ftp-setup-un-field {
  width: 49%;
}

.ftp-setup-rmtFolder-field {
  width: 99%;
}

.proc-def-upld-div {
  width: 30px;
  align-content: center;
  vertical-align: middle;
}

.proc-def-actionBtns-container {
  width: 10vw;
}

.proc-def-actionBtn-div {
  width: 28px;
}

.proc-def-status-div {
  width: 15vw;
}

.proc-def-msgData-listItem {
  margin: 10px 0px 10px 0px;
  height: auto;
}

.proc-def-msgData-listItem-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 0 0;
}

.proc-def-filtByCntry-field {
  width: 20%;
  min-width: 150px !important;
}

.proc-def-filtByNum-field {
  width: 15%;
  min-width: 135px !important;
}

.proc-def-dirn-field {
  width: 9%;
  min-width: 105px !important;
}

.proc-def-filtByMsgCont-field {
  width: 100%;
  min-width: 135px !important;
}

.review-data-table-container {
  position: relative;
  height: 60vh !important;
  overflow: auto;
}

.surv-schedular-scheduler-div {
  width: 685px;
  margin: 1px auto;
}

.surv-schedular-accordion {
  width: 75%
}

.surv-summ-container {
  margin: 10px auto !important;
  width: 100vw !important;
}

.surv-summ-genStat-container {
  width: 50vw !important;
}

.surv-summ-genStat-content {
  display: flex;
  flex-flow: row;
  width: 500px;
  height: 30px;
  align-content: center;
  align-items: center;
}

.surv-summ-InvMsg-div {
  width: 300px !important;
}

.surv-summ-resltVal-div {
  width: 50px;
  text-align: right;
}

.txt-analysis-table-container {
  position: relative;
  height: 60vh;
  overflow: auto;
}

tr.txt-analysis-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.txt-analysis-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

tr.txt-analysis-element-row.example-expanded-row:active {
  background: #efefef;
}

.txt-analysis-search-field {
  width: 49%;
}

//----- Data Analysis Component End --------------------------------------------
//----- Credit Card Component Start --------------------------------------------
.cc-input-container {
  display: flex;
  flex-flow: row;
}

.cc-input-element {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: inherit;
  text-align: center;
}

.cc-input-expiry {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: inherit;
  text-align: center;
}

.cc-input-cvc {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: inherit;
  text-align: center;
}

.cc-input-part-spacer {
  opacity: 0;
  transition: opacity 200ms;
  width: 5px;
}

.cc-input-spacer {
  opacity: 0;
  transition: opacity 200ms;
}

.cc-pmnt-form-field {
  width: 49%;
}
//----- Credit Card Component End ----------------------------------------------

//----- Country Combo Component Start ------------------------------------------
.cntry-cmbo-form-field {
  width: 33%;
}
//----- Country Combo Component End --------------------------------------------

//----- Communication Component Start ------------------------------------------

::ng-deep .tooltip-custom-class .tooltip-inner {
  font-size: 250%;
}

.comm-edtr-icon-div {
  width: 70px;
  align-content: center;
  vertical-align: middle;
}

.comm-edtr-icon {
  font-size: 40px;
  width: 70px;
  height: 70px;
}

.comm-edtr-WA-icon {
  font-size: 40px;
}

.comm-edtr-name-container {
  display: flex;
  flex-flow: column;
  width: 50vw;
}

.comm-edtr-type-container {
  width: 20vw;
}

.comm-edtr-space-container {
  width: 10vw;
}

.comm-edtr-btn-container {
  width: 28px;
}

.comm-edtr-divider-div {
  width: 49%;
}

.comm-edtr-diyEdtr-container {
  width: 100%;
  margin: 1px auto;
}

.comm-list-notSelected {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: white;
}

.comm-list-selected {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background: #cce5ff;
}

.comm-list-sidenav-content {
  display: flex !important;
  justify-content: flex-end;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.comm-list-main-container {
  width: 100%;
  margin-top: 10px;
}

.comm-list-sidenav-div {
  display: flex !important;
  flex-flow: column;
  width: 77vw;
}

.comm-list-editor-div {
  height: 68vh;
  overflow-y: auto;
}

.comm-list-sidebar-div {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.wa-main-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.wa-main-content {
  width: 550px;
  display: flex;
  flex-flow: column;
}

.wa-content-divider {
  margin: 5px 0 5px 0;
}

.comm-top-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.comm-card-container {
  height: 100%;
  overflow-y: auto;
}

.comm-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%
}

.comm-card {
  margin: 10px;
  width: 400px;
}

.comm-list-card {
  margin: 10px;
  width: 100%;
}

.comm-list-card-content {
  padding-left: 15px;
}

//----- Communication Component End --------------------------------------------

//----- Randomise Question Component Start -------------------------------------

.randomise-qnTable-container {
  height: 500px;
  width: 100%;
  overflow: auto;
}

.randomise-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
}

//----- Randomise Question Component End ---------------------------------------

//----- Range Box Component Start ----------------------------------------------

.range-input-container {
  display: flex;
}

.range-input-element {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: inherit;
  text-align: center;
}

.range-input-spacer {
  opacity: 0;
  transition: opacity 200ms;
}

//----- Range Box Component End ------------------------------------------------

//----- Download Report Component Start ----------------------------------------
.dwnld-rprt-icon-div {
  float: left;
  width: 10%;
}

.dwnld-rprtExcl-icon {
  font-size: 28px;
  color: green;
}

.dwnld-rprtPdf-icon {
  font-size: 28px;
  color: red;
}

.dwnld-rprt-txt-div {
  float: right;
  width: 90%;
}

.dwnld-rprt-button-div {
  float: right;
  width: 100%;
  margin-top: 10px;
}

//----- Download Report Component End ------------------------------------------

//----- Edit Report Component Start --------------------------------------------

.edit-report-save-button {
  width: 135px;
  background-color: #19284c;
  color: #ffffff;
  font-weight: 500;
  border-radius: 8px;
  margin: 10px 0 0 0;
}


form.edit-report-form {
  margin: 20px !important;
}

.edit-report-fields-container {
  width: 550px;
  display: flex;
  flex-flow: column;
}

//----- Edit Report Component End -----------------------------------------------

//----- Selected Question Component Start ---------------------------------------

.selectedQn-main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.selectedQn-chart-div {
  margin: 10px 0;
  margin-top: 75px;
}

.selectedQn-qnData-container {
  float: right;
  width: 100%;
  display: block;
}

.selectedQn-sidenav-content {
  height: 78.5vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.selectedQn-sidenav-div {
  width: 97%;
}

.selectedQn-downloadPDF-icon {
  font-size: 28px;
  color: red;
}

//----- Selected Question Component End -----------------------------------------


//----- Report Component Start --------------------------------------------------

.reports-rightNav-scroll::-webkit-scrollbar {
  display: none;
}

.reports-question-sidebar-box {
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  font-size: 14px;
  cursor: pointer;
}

.reports-question-sidebar-box:last-child {
  border-bottom: solid 1px #ccc;
}

.reports-qnSidebar-custPlaceholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.reports-qnSidebar-text {
  padding: 0 10px;
  width: 100%;
}

.reports-leftControls-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.reports-drag-question {
  cursor: move;
}

.reports-sidenav-content {
  display: flex !important;
  justify-content: center !important;
}

.reports-leftSidebar-list {
  width: 300px;
  padding: 0px;
  max-width: 100%;
  border-top: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  overflow: hidden;
  height: 61vh;
}

.reports-leftSidebar-list.cdk-drop-list-dragging .reports-question-sidebar-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.reports-question-icon {
  color: #a3a3a3;
  height: 15px;
  width: 15px;
  font-size: 12px;
  margin: 2px;
}

.reports-mat-sidenav-left {
  width: 280px;
  border-bottom: solid 1px #ccc;
  border-right: solid 1px #ccc;
  background-color: #ffffff;
  border: 1px solid #ccc;
}

.reports-mat-sidenav-right {
  width: 280px;
  border-left: solid 1px #ccc;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-bottom: solid 1px #ccc;
}

.reports-notSelected {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
  background: white;
}

.reports-selected {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
  background: #cce5ff;
}

.reports-slctAll-container {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.reports-qnProp-heading-container {
  display: flex;
  align-items: baseline;
}

.reports-qnProp-heading-content {
  text-align: center;
  margin: 5px;
  margin-top: 10px;
}

.reports-chartSlctr-div {
  margin: 10px 20px;
  text-align: center;
}

.reports-clrScheme-div {
  margin: 10px 10px 10px 38px;
}

.reports-content-div {
  height: 68vh;
  overflow-y: auto;
  width: 53vw;
  padding: 5px;
}

//----- Report Component End ----------------------------------------------------

//----- Reset Password Component Start ------------------------------------------

.reset-password-main-div {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.reset-password-form {
  padding: 20px;
}

.reset-password-card {
  margin: 100px auto;
  padding: 10px;
}

//----- Reset Password Component End --------------------------------------------

//----- Advanced Timings Component Start ----------------------------------------

.advTimings-form-field {
  width: 180px;
}

//----- Advanced Timings Component End ------------------------------------------

//----- Time Picker Component Start ---------------------------------------------

.timePkr-hr-field {
  width: 100px !important;
}

.timePkr-minute-field {
  width: 110px !important;
}

.timePkr-sec-field {
  width: 70px !important;
}

//----- Time Picker Component End -----------------------------------------------

//----- TimeZone Picker Component Start -----------------------------------------

.timeZonePkr-form-field {
  width: 350px;
}

//----- TimeZone Picker Component End -------------------------------------------

//----- Scheduler Component Start -----------------------------------------------

.schdlr-timeZnPckr-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px
}

.schdlr-dly-form-field-container {
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

.schdlr-dly-form-field-content {
  padding: 5px 0 0;
}

.schdlr-dly-day-selector {
  width: 95px !important;
  margin: 10px 0
}

.schdlr-dly-time-picker {
  width: 82px !important;
}

.schdlr-wkly-every-span {
  font-weight: 400 !important;
}

.schdlr-mnthly-spDay-container {
  font-weight: 400 !important;
  align-items: center;
}

.schdlr-mnthly-spDay-formField {
  width: 100px !important;
  margin: 10px 0;
}

.schdlr-mnthly-spWkday-formField {
  width: 100px !important;
}

.schdlr-advanced-timings {
  margin-left: 105px;
  padding: 5px;
}

//----- Scheduler Component End -------------------------------------------------

//----- Add Upload Respondent Component Start -----------------------------------

.add-upld-example-element-description {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.add-upld-button-row button {
  margin: 5px;
}

.add-upld-download-temp-link {
  color: orangered;
}

.add-upld-invdl-form-field {
  width: 400px;
}

.add-upld-upload-card, .add-upld-new-panellist-card {
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: center;
}

form.add-upld-normal-form {
  margin: 10px;
}

//----- Add Upload Respondent Component End -------------------------------------


//----- Select Respondent Landing Page Component Start --------------------------

.slctRspndnt-main-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slctRspndnt-buy-audience-content {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.slctRspndnt-audienceCard-div {
  width: 75%;
  background: #fafafa;
  margin: auto;
  border: 1px solid #ccc;
}

.slctRspndnt-pnlSlctrTemp-div {
  height: 55vh;
  overflow: auto;
  margin: 5px;
  background-color: #fafafa;
}

.slctRspndnt-showMainNot-div {
  height: 20px;
}

.slctRspndnt-showMain-div {
  color: #19284c;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  margin: 10px 0;
}

.slctRspndnt-card-div {
  margin: 20px;
  width: 245px;
  height: 245px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.slctRspndnt-secondstep-cards {
  height: 245px;
  padding: 0;
}

.slctRspndnt-secondstep-cards:hover {
  background: rgba(0,0,0,.04);
}

.slctRspndnt-card-title-container {
  padding: 10px;
}

.slctRspndnt-card-title-content {
  color: #19284c;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.slctRspndnt-card-img {
  width: 50px;
  margin: 0 !important;
}

.slctRspndnt-card-description-container {
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slctRspndnt-card-description-content {
  color: #6d6d6d;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  padding: 0 20px;
}

.slctRspndnt-or-separator {
  margin: 30px 0 15px;
  text-align: center;
  border-top: 2px solid #ccc;
  width: 50px;
}

.slctRspndnt-or-separator span {
  padding: 0 10px;
  background: #ffffff;
  position: relative;
  top: -11px;
  z-index: 1;
}

//----- Select Respondent Landing Page Component End ----------------------------


//----- Settings Component Start ------------------------------------------------

.settings-forgotPwd-form {
  padding: 20px;
}

.settings-card {
  padding: 0px;
  margin: 5px;
}

//----- Settings Component End --------------------------------------------------


//----- Message History Component Start ------------------------------------------------

.msg-hist-element-row {
  position: relative;
  cursor: pointer;
}

.msg-hist-element-row:hover {
  background: #f5f5f5;
}

.msg-hist-list0 {
  margin: 10px 0px 10px 0px !important;
  height: auto !important;
}

.msg-hist-list0-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0 0 0;
}

.msg-hist-filtByCountry-field {
  width: 20%;
  min-width: 150px;
}

.msg-hist-filtByNum-field {
  width: 15%;
  min-width: 135px;
}

.msg-hist-msgType-field {
  width: 9%;
  min-width: 105px;
}

.msg-hist-filtByMsg-field {
  width: 100%;
  min-width: 135px;
  margin-top: 3px;
}

.msg-hist-dtl-container {
  width: 100%
}

.msg-hist-dtl-content {
  max-height: 75vh;
}

.msg-hist-msg-content-div {
  white-space: pre-line;
}

//----- Message History Component End --------------------------------------------------

//----- SMS Tools Component Start ------------------------------------------------------

.sms-tools-container {
  margin: 15px;
}

.sms-tools-titlepanel-div {
  float: left;
  font-weight: 500;
}

//----- SMS Tools Component End --------------------------------------------------------

//----- Survey Dashboard Component Start -----------------------------------------------

.surv-dashBrd-progress-container {
  display: flex;
  align-items: baseline;
}

.surv-dashBrd-progressBar-div {
  height: 4px;
  width: 150px;
  margin-top: 20px;
}

//----- Survey Dashboard Component End -------------------------------------------------

//----- Start From Template Component Start --------------------------------------------

.start-frm-temp-heading {
  color: #19284c;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: left;
  margin: 5px 0;
}

.start-frm-temp-template-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin: 10px 0;
}

.start-frm-temp-template-content {
  width: 230px;
  height: 390px;
  text-decoration: none;
  cursor: pointer;
  margin: 10px 20px 20px 10px;
}

.start-frm-temp-cards {
  height: 400px;
}

.start-frm-temp-cards:hover {
  background: rgba(0,0,0,.04);
}

.start-frm-temp-title-container {
  height: 180px;
}

.start-frm-temp-title-content {
  color: #19284c;
  height: 45px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left;
  padding: 5px;
  display: flex;
  align-items: center;
}

.start-frm-temp-img {
  width: 100%;
  height: 135px;
}

.start-frm-temp-description-container {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.start-frm-temp-description-content {
  color: #6d6d6d;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  padding: 5px;
}

.start-frm-temp-channel-preview-container {
  display: flex;
  align-items: flex-end;
}

.start-frm-temp-chnl-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}

.start-frm-temp-chnl-container > div > .mdc-button {
  justify-content: flex-start;
}

.start-frm-temp-channelPvw-content {
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
}

.start-frm-temp-channel-text {
  font-size: 11px;
}

.start-frm-temp-template-container-list {
  width: 50%;
  display: flex;
  align-items: center;
}

.start-frm-temp-container-content-list {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;
}

.start-frm-temp-title-content-list {
  color: #19284c;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left;
  padding: 5px;
  display: flex;
  align-items: center;
}

.start-frm-temp-preview-container-list {
  width: 50%;
  display: flex;
  justify-content: center;
}

.start-frm-temp-channel-container-list {
  width: 70%;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 0 5px;
}

.start-frm-temp-channelPvw-list {
  display: flex;
  align-items: center;
}

//----- Start From Template Component End ----------------------------------------------

//----- Message Builder Component Start ------------------------------------------------

.msg-builder-editor-div {
  width: 600px;
}

.msg-builder-saveBtn-div {
  margin: 10px 0px;
}

//----- Message Builder Component End --------------------------------------------------

//----- Survey Distribution Component Start --------------------------------------------

.surv-distr-send-email-card {
  display: flex;
  margin: 10px;
  padding: 10px;
  width: 90%;
}

.surv-distr-send-qr-card {
  padding: 10px;
  width: 90%;
}

.surv-distr-card-div {
  margin: 0 20px 20px;
  width: 110px;
  height: 130px !important;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
}

.surv-distr-card {
  height: 130px;
}


.surv-distr-textarea {
  margin-top: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  opacity: .5;
  color: #000;
}

.surv-distr-distribution-cards {
}

.surv-distr-distribution-cards:hover {
  background: rgba(0,0,0,.04);
}

.surv-distr-inviteMessage {
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.surv-distr-header-span {
  color: #19284c;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: center;
  margin: 15px 0;
}

.surv-distr-sendType-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.surv-distr-send-whatsapp-img {
  height: 35px;
  width: 40px;
  padding: 5px;
}

.surv-distr-send-type-img {
  height: 35px;
  width: 40px;
}

.surv-distr-send-type-span {
  color: #6d6d6d;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

.surv-distr-jobmaster-header {
  text-align: center;
  padding-bottom: 5px;
}

.surv-distr-send-qr-div {
  text-align: center;
  padding-bottom: 5px;
}

.surv-disr-icon {
  font-size: 14px;
}

//----- Survey Distribution Component End ----------------------------------------------

//----- Window Component Start ---------------------------------------------------------

.window-list {
  display: flex;
  align-content: center;
  align-items: center;
}

.window-from-field {
  width: 85px;
}

.window-add-del-icon {
  font-size: 20px;
}

//----- Window Component End -----------------------------------------------------------

//----- Survey Details Navigation Component Start --------------------------------------

.step-3-scheduleType-content {
  margin: 10px 0 0 20px;
  color: #6d6d6d;
  font-weight: 500;
}

.survey-dtlNav-advTime-container {
  width: 100%;
  margin: 1px auto;
}

.survey-dtlNav-schedule-container {
  width: 685px;
  margin: 1px auto;
}

.survey-dtlNav-more-container {
  width: 75%
}

//----- Survey Details Navigation Component End ----------------------------------------

//----- Edit Panel Component Start -----------------------------------------------------
.edit-panel-top-container {
  display: flex;
  align-items: center;
  margin: 5px;
  justify-content: flex-start;
  color: #6d6d6d;
}

.outer-table-container {
  position: relative;
}

tr.edit-panel-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.edit-panel-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.edit-panel-element-row td {
  border-bottom: 1px solid #ccc !important;
}

//----- Edit Panel Component End -------------------------------------------------------

//----- Edit Toolbar Component Start ---------------------------------------------------

.edit-toolbar-name-div {
  font-size: 10px;
  margin-bottom: -8px;
  margin-left: 16px
}

.edit-toolbar-edit-div {
  margin-top: 1px;
}

.edit-toolbar-edit-icon {
  margin-left: 5px !important;
  font-size: 15px !important;
}

//----- Edit Toolbar Component End -----------------------------------------------------

//----- Navigation Component Start -----------------------------------------------------

.nav-toolbar-container {
  width: 100% !important;
  height: 80px !important;
  background-color: #ffffff !important;
  padding: 15px 0px 15px 40px !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #eee;
}

.nav-header-right-container {
  display: flex;
  margin-right: 10px;
  // width: 125px;
  justify-content: flex-end;
  color: #19284c;
  align-items: flex-end;
}

.nav-sidenav {
  width: 320px;
  background-color: #f6f6f6 !important;
}

.nav-navlist {
  color: #6d6d6d;
  font-weight: 400;
}

a.nav-navlist:hover, a.nav-navlist:active {
  color: #62a8ff;
  font-weight: 500;
}

.nav-is-active {
  color: #62a8ff;
  font-weight: 500;
}


.nav-first-tile-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.nav-navlist-container {
  padding: 2px 25px 25px 25px;
  display: flex;
  flex-direction: column;
}

.nav-navlist-item-div {
  margin-bottom: 12px;
  cursor: pointer;
  box-shadow: 5px 5px 5px #f0f0f0;
}

.nav-navlist-item-sms-div {
  cursor: pointer;
  box-shadow: 5px 5px 5px #f0f0f0;
}

.nav-main-content {
  padding: 15px;
  width: 100%;
}

.nav-admin-tools-btn {
  background-color: white !important;
  width: 100%;
  text-align: left;
}

@media (max-width:599px) {
  .nav-main-content {
  }
}

@media (max-height:375px) {
  .nav-main-content {
  }
}

@media (min-height:960px) and (max-height:1024px) {
  .nav-main-content {
  }
}

@media (min-height:721px) and (max-height:959px) {
  .nav-main-content {
  }
}

@media (min-height:1025px) and (max-height:1366px) {
  .nav-main-content {
  }
}

.nav-footer {
  margin: 10px;
}

.nav-footer span, .nav-footer a {
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  //letter-spacing: 0.92px;
  color: #656565;
}

//----- Navigation Component End -------------------------------------------------------

//----- Register Component Start -------------------------------------------------------
.register-success-div {
  width: 225px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid;
  color: #4F8A10;
  background-color: #DFF2BF;
  padding: 1rem;
  margin: 5px 0 0;
}

.register-terms-agreement-span {
  color: #6d6d6d;
  font-weight: 400;
  white-space: pre-wrap;
}

.register-first-tile-container {
  margin-bottom: 10px;
  cursor: pointer;
}

.register-first-tile-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 35px;
  background-color: #ffffff;
  border-radius: 4px;
  padding-top: 6px;
}

.register-or-separator {
  margin: 30px 0 20px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.new-register-or-separator {
  margin: 15px 0 5px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.register-or-separator span {
  padding: 0 10px;
  background: #f6f6f6;
  position: relative;
  top: -11px;
}

.new-register-or-separator span {
  padding: 0 10px;
  background: #f6f6f6;
  position: relative;
  top: -11px;
}

.register-form {
  padding: 20px;
}

::ng-deep .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px;
}

::ng-deep .mat-form-field-label-wrapper {
  top: -1.5em;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}

::ng-deep .mat-form-field-wrapper {
  padding-bottom: 0em;
  line-height: 1.15;
}

//----- Register Component End ---------------------------------------------------------

//----- Login Component Start ----------------------------------------------------------

.social-login-separator {
  height: 30px;
  border-left: 2px solid #ccc;
}

.loginPage-signup-btn-handset {
  width: 100%;
  text-align: center;
  border-radius: 0 0 16px 16px;
  padding: 4px;
}

.login-form {
  padding: 20px;
}

/* add appropriate colors to fb, twitter and google buttons */
.fb {
  background-color: #3B5998;
  color: white;
  width: 100%;
  white-space: pre-wrap;
}

.google {
  background-color: #dd4b39;
  color: white;
  width: 100%;
  margin-top: 5px;
  white-space: pre-wrap;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-flow:row;
  padding: 8px;
  vertical-align: middle;
  justify-content: center;
}

.footer-terms-div {
  margin: 0 10px 0 10px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.handset-footer-email-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.handset-footer-email {
  color: #62a8fe;
  font-size: 14px;
  font-weight: normal;
}

.handset-footer-bottom-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.login-forgotPwd-hint {
  color: #464646;
}

.login-forgotPwd-hint:hover {
  color: #464646;
}

.login-social-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: row;
  background-color: #ffffff;
  align-items: center;
}

.login-google-container {
  width: 50%;
  margin: 0 -40px 0 18%;
}

.login-fb-container {
  width: 50%;
  border: none !important;
  height: 100% !important;
  padding-top: 7px !important;
}

.login-needAcct-div {
  text-align: center;
  padding: 25px;
}

.login-signup-link {
  color: #1a284d;
  font-weight: 600;
  letter-spacing: 1px;
}

.login-tile2-container {
  background-color: #1a284d;
}

.login-tile2-headerSpan-div {
  margin: 10px 10px 20px 10px;
}

.login-tile2-header-span {
  height: 38px;
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
  height: 38px;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
}

.login-tile2-list-div {
  color: #ffffff;
  padding: 0px 10px 10px 10px;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.22px;
  font-weight: 300;
}

//----- Login Component End ------------------------------------------------------------
//----- DIY Landing Component Start ----------------------------------------------------
.toolbar-handset {
  height: 80px !important;
  padding: 24px 116px 23px;
  background-color: #ffffff;
}

.toolbar-handset-container {
  //display: none;
}

.diy-landing-grid-tile {
  top: 1px !important;
}

.login-signup-div-handset {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 45px;
  background: #f6f6f6;
}

.login-btn-handset {
  width: 50%;
  text-align: center;
  border-radius: 0 0 0 16px;
  padding: 4px;
}

.signup-btn-handset {
  width: 50%;
  text-align: center;
  border-radius: 0 0 16px 0;
  padding: 4px;
}

.diy-landing-first-tile-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 65px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}

.diy-landing-chatSol-icon {
  color: #69acfc;
}

.diy-landing-tile2 {
  background-color: #1a284d;
}

.diy-landing-tile2-span1-container {
  margin: 40px 10px 0px 45px;
}

.diy-landing-tile2-span1 {
  height: 38px;
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
}

.diy-landing-tile2-span2-container {
  margin: 10px 10px 10px 45px;
}

.diy-landing-tile2-span2 {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
}

.diy-landing-tile2-list-div {
  color: #ffffff;
  padding: 0px 10px 0px 45px;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.22px;
  font-weight: 300;
}

.diy-landing-tile2-bottomSpan-container {
  color: #ffffff;
  padding: 0px 10px 10px 45px;
  display: flex;
  font-size: 13px;
  letter-spacing: 1.22px;
  font-weight: 300;
  font-style: italic;
}

.diy-landing-separator {
  height: 30px;
  border-left: 2px solid #ffffff;
}

.blue-icon {
  color: #62a8fe;
}

.diy-landing-getEstimate-btn {
  color: #ffffff;
  border-radius: 8px;
  font-weight: 500;
  width: 150px;
  height: 40px;
  background-color: #19284c !important;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
}

.create-account-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  background-color: #65aafc;
  border: 1px solid #ccc;
  height: 51px;
  position: fixed;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  justify-content: center;
}

.create-account-content {
  margin: 10px;
  display: flex;
  align-items: center;
}

.create-account-getStarted-span {
  color: #ffffff;
}

.create-account-div-handset {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  background-color: #65aafc;
  border: 1px solid #ccc;
  height: 125px;
  position: fixed;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  justify-content: center;
}

.create-account-content-handset {
  margin: 10px;
  display: flex;
  align-items: center;
  padding: 0px 5px 40px 30px;
}

.create-account-getStarted-span-handset {
  color: #ffffff;
  font-size: 13px;
  font-weight: 300;
}

.diy-landing-createAcct-btn {
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.diy-landing-createAcct-btn-handset {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.footer-left-content {
  width: 55%;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
}


.estimate-grid-heading-div {
  margin: 10px 10px 10px 10px;
  padding: 0 40px;
}

.estimate-grid-heading-span {
  font-weight: 600;
  font-size: 28px;
  height: 72px;
  font-family: Roboto;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 0.68px;
  color: #19284c;
}

.estimate-grid-point-one-div {
  padding: 10px 50px;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.22px;
  font-weight: 300;
}

.estimate-grid-point-two-div {
  padding: 0px 50px;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.22px;
  font-weight: 300;
}

.estimate-grid-point-three-div {
  padding: 10px 50px;
  display: flex;
  font-size: 16px;
  letter-spacing: 1.22px;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .estimate-grid-point-one-div {
    padding: 0px 50px;
  }

  .estimate-grid-point-two-div {
    padding: 0px 50px;
  }

  .estimate-grid-point-three-div {
    padding: 0px 50px;
  }

  .footer-left-content {
    width: 100%;
  }

  .footer-version-div {
    width: 100%;
    margin-left: 0;
  }

  .footer-terms-div {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .footer-privacy-div {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .diy-landing-first-tile-div {
    height: 50px;
  }
}

.handset-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  color: #000000;
  margin: 0px;
  padding: 0 10px;
}

.handset-footer span, .handset-footer a {
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  color: #656565;
}

.fa {
  padding: 2px;
  font-size: 20px;
  width: 30px;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

//@media (max-width: 600px) {
/*  mat-toolbar {
    display: none !important;
  }

  .toolbar-logo {
    width: 186px;
    height: 33px;
    object-fit: contain;
    padding: 0 !important;
  }

  .toolbar-handset-container {
    display: block;
  }*/
//}

.diy-landing-content-bgColor .diy-landing-gridList-bgColor {
  background-color: #f6f6f6;
}

.diy-landing-first-tile-span {
  color: #6d6d6d;
  font-weight: 400;
}

.diy-landing-first-tile-container {
  margin-bottom: 12px;
  cursor: pointer;
  box-shadow: 5px 5px 5px #f0f0f0;
}

.diy-landing-fast-tile-bottom-container {
  cursor: pointer;
  box-shadow: 5px 5px 5px #f0f0f0;
}

.diy-landing-first-tile-bottom-div {
  margin: 0px 0 0 0;
  text-align: left;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.89px;
  color: #19284c;
}

.diy-landing-first-tile-bottom-span {
  font-size: 17px;
}

//----- DIY Landing Component End ------------------------------------------------------

//----- Cint Mapper Component Start ----------------------------------------------------

.cint-mapper-main-container {
  width: 100%;
  height: 96%;
}

.cint-mapper-cat-selector-container {
  float: left;
  width: 55%;
  overflow: scroll;
}

.cint-mapper-selected-div {
  width: 280px;
  background-color: #ffffff;
}

.cint-mapper-selected-div-content {
  text-align: center;
  width: 100%;
  margin: 5px;
}

.cint-mapper-mat-sidenav-right-div {
  width: 100%;
  height: 100%;
}

.cint-mapper-mat-sidenav-right {
  width: 280px;
  border-left: solid 1px #ccc;
  background-color: #ffffff;
  border: 1px solid #ccc;
}

.cint-mapper-category-div {
  margin-top: 40px;
}

.cint-mapper-category-div-content {
  margin: 5px;
}

.cint-mapper-radio-icon {
  font-size: 15px;
}

//----- Cint Mapper Component End ------------------------------------------------------

//----- Chat Component Start -----------------------------------------------------------

.compose-msg-content {
  white-space: pre-wrap;
}

.compose-msg-container {
  padding-right: 2px;
}

.compose-msg-content-div {
  padding: 0 0 0 10px;
}

.compose-msg-from-div {
  margin: 5px 0 5px 0;
}

.compose-msg-divider {
  margin: 0 0 5px 0px;
}

.compose-msg-reply-btn {
  border-radius: 20px !important;
}

.compose-msg-main-div {
  width: 99.5%;
  padding-left: 10px;
}


.inbox-compose-nomsg-container {
  align-items: center;
  margin: 5px;
  color: #6d6d6d;
}

.inbox-compose-msg-container {
  display: flex;
  align-items: flex-start;
  margin: 5px;
  color: #6d6d6d;
}

.inbox-outer-table-container {
  position: relative;
}

.inbox-table-container {
  position: relative;
  overflow: auto !important;
}

.inbox-notRead {
  font-weight: 900;
}

//----- Chat Component End -------------------------------------------------------------

//----- Category Selector Component Start ----------------------------------------------

.cat-selector-catBtn-div {
  float: left;
  margin: 2px;
}

.cat-selector-btn-container {
  clear: left;
  width: 100%;
}

.cat-selector-select-div {
  float: left;
  margin: 5px;
}

//----- Category Selector Component End ------------------------------------------------


//----- Category Button Component Start ------------------------------------------------

.cat-btn-outline-button {
  border: 1px solid #62a8ff !important;
  color: #62a8ff !important;
}

.cat-btn-selected-button {
  border: 1px solid #62a8ff !important;
  background-color: #62a8ff !important;
  color: #ffffff !important;
}

.cat-btn-outline-button:focus {
  outline: 0;
}

//----- Category Button Component End --------------------------------------------------


//----- Card Payment Component Start ---------------------------------------------------

.fin-trans-username-div {
  margin: 30px;
}

.fin-trans-daterange-field {
  width: 20%;
}

//----- Card Payment Component End -----------------------------------------------------

//----- Breadcrumb Component Start -----------------------------------------------------

.breadcrumb {
  background: none;
  font-size: 1.1em;
  margin: 0;
}

.breadcrumb a, .breadcrumb span {
  color: darkgrey;
}

.breadcrumb a:hover, .breadcrumb span:hover {
  color: dimgrey;
  text-decoration: none;
}

.breadcrumb li {
  list-style: none;
  float: left;
  margin: 5px;
}

.breadcrumb li:last-child {
  margin-right: 20px;
}

.breadcrumb li::after {
  /*content: ">";*/
  color: darkgrey;
}

.breadcrumb li:last-child::after {
  content: "";
}

//----- Breadcrumb Component End -------------------------------------------------------

//----- Survey Style Component Start ---------------------------------------------------

.themeThumbnail {
  width: 60px;
}

.themeThumbnailLight {
  width: 60px;
  background-color: #cccccc;
}

.theme-selector-container {
  text-decoration: none;
  cursor: pointer;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.theme-selector-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0px;
}

.theme-selector-color-container {
  width: 20px;
  height: 20px;
  display: flex;
  border: 0.5px solid #ccc;
}

.survey-style-preview-div {
  width: 500px;
  height: 299px;
  border: 1px solid #cccccc;
}

.survey-style-useDflt-chkbox {
  padding: 0 10px 0 0;
}

.survey-style-headerLogo-img {
  width: 120px;
}

//----- Survey Style Component End -----------------------------------------------------

//----- System Component Start ---------------------------------------------------------

.system-title {
  justify-content: flex-end;
  width: 50%;
}

.system-desc {
  justify-content: flex-start;
  width: 50%;
}

.system-update-btn-container {
  margin-bottom: 60px;
}

//----- System Component End -----------------------------------------------------------

//----- T&C Component Start ------------------------------------------------------------

.fixed-main-content {
  width: 100%;
  height: 84vh;
  padding: 50px;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  margin: 0px;
}

.fixed-footer span, .fixed-footer a {
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  color: #656565;
}

//----- T&C Component End --------------------------------------------------------------

//----- TinyUrl Component Start --------------------------------------------------------

.tUrl-status-span-width {
  width: 400px;
}
//----- TinyUrl Component End ----------------------------------------------------------

//----- Toolbar Component Start --------------------------------------------------------

.mat-toolbar-container-noshadow {
  width: 100% !important;
  height: 80px !important;
  position: fixed;
  z-index: 100;
  background-color: #ffffff !important;
  padding: 17px 24px 15px 40px !important;
}

.mat-toolbar-container-noshadow-new {
  width: 100% !important;
  height: 80px !important;
  position: fixed;
  z-index: 100;
  background: rgba(255,255,255,0.9) !important;
  backdrop-filter: blur(5px);
  padding: 17px 24px 15px 40px !important;
}

.mat-toolbar-container {
  width: 100% !important;
  height: 80px !important;
  position: fixed;
  z-index: 100;
  background-color: #ffffff !important;
  padding: 17px 24px 15px 40px !important;
  box-shadow: 0 4px 10px 0 rgba(219, 219, 219, 0.23);
}

.toolbar-logo {
  width: 186px;
  height: 48px;
  object-fit: contain;
  padding-left: 10px;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.toolbar-hellodiysurveyscom {
  font-size: 14px;
  width: 159px;
  height: 16px;
  font-family: Roboto;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.07px;
  color: #1a284d;
}

//----- Toolbar Component End ----------------------------------------------------------

//----- Transfer Item Component Start --------------------------------------------------
.top-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 5px;
}

.trans-item-container {
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

//----- Transfer Item Component End ----------------------------------------------------


//----- Change Email Component Start ---------------------------------------------------

.chng-email-header-div {
  color: #19284c;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  margin: 5px 0;
}
//----- Change Email Component End -----------------------------------------------------

//----- Change Username Component Start ---------------------------------------------------

.chng-un-controls-container > * {
  width: 100%;
}

.chng-un-select-name-div {
  width: 550px;
  display: flex;
  flex-flow: column;
}

.chng-un-save-button {
  width: 135px;
  background-color: #19284c;
  color: #ffffff;
  font-weight: 500;
  border-radius: 8px;
  margin: 10px 0 0 0;
}

form.chng-un-form {
  margin: 20px !important;
}

//----- Change Username Component End -----------------------------------------------------

//----- Two Factor Component Start --------------------------------------------------------

.twoFA-main-div {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.twoFA-card {
  margin: 100px auto;
  padding: 10px;
}

.forgot-password-card {
  margin-top: 70px;
  padding: 0px;
}

@media (max-height:375px) {
  .forgot-password-card {
    margin-bottom: 20px;
  }
}

.twoFA-forgot-password-form {
  padding: 20px;
}

.twoFA-authCode-container {
  padding-right: 10px;
}

//----- Two Factor Component End ----------------------------------------------------------

//----- User Details Component Start ------------------------------------------------------

.user-details-avatarIcon {
  border-radius: 50%;
  color: white;
  background-color: darkgray;
  font-size: 10em;
  width: 150px;
  height: 150px;
  text-align: center;
  vertical-align: text-bottom;
  padding-top: 61px;
}

.user-details-avatarImage {
  border-radius: 50%;
  color: white;
  background-color: darkgray;
  font-size: 1em;
  width: 150px;
  height: 150px;
  text-align: center;
  vertical-align: text-bottom;
}

div.container {
  width: 100%;
  margin: 0px;
}

.fill-remaining-space {
  flex: 1 1 auto;
  /*flexbox layout*/
}

form.normal-form {
  margin: 10px;
}

.user-details-controls-container {
  width: 60%;
  margin: 1px auto;
}

.user-profile-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
  text-align: center;
}

.schedule-container {
  width: 100%;
  text-align: center;
}

.controls-container > * {
  width: 100%;
}

.add-bottom-padding {
  padding-bottom: 10px;
}

mat-radio-group mat-radio-button {
  margin-left: 5px;
}

.button-row button {
  margin: 5px;
}

.example-chip-list {
  width: 100%;
  margin-top: 10px;
}

.question-sidebar-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.user-details-container {
  //margin: 15px;
}

.user-details-header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.user-details-header-divs {
  margin-right: 10px;
}

.user-details-acctLoading-container {
  float: left;
  height: 25px;
  width: 200px;
}

.user-details-dialog-content {
  clear: both;
  margin-top: 10px;
}

.user-details-invitation-container {
  display: flex;
  flex-flow: row;
}

.user-details-invite-username-div {
  width: 75%;
}

.user-details-invite-role-div {
  width: 100px;
}

.user-details-dialog-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f2f2f2;
  padding: 0 5px 0 15px;
  border-radius: 8px;
}

.user-details-dialog-close-icon {
  font-size: 20px;
}

.user-details-profile-image-container {
  clear: both;
  margin-top: 10px;
}

.user-details-profile-image-container {
  width: 250px;
  height: 200px;
  float: left;
}

.user-details-profile-image-divider {
  margin-top: 10px;
}

.user-details-expansion-panel-title {
  font-weight: 500;
}


.user-details-bottom-padding {
  padding: 50px;
}

.user-details-delete-acct-container {
  border: solid 1px red;
  border-radius: 5px;
  padding: 10px;
}

.user-details-delete-org-container {
  display: flex;
  flex-flow: row;
  padding: 5px;
}

.user-details-delete-org-header-div {
  width: 50%;
}

.user-details-delete-org-button-div {
  width: 50%;
  vertical-align: initial;
}

.user-details-transfer-org-container {
  display: flex;
  flex-flow: row;
  padding: 5px;
  width: 100%;
}

.user-details-twoFA-container {
  margin-top: 20px;
}

.user-details-roles-div {
  margin: 12px 4px;
}

.user-details-status-div {
  margin: 13px 5px;
}

.user-details-update-account-div {
  margin: 13px 0;
}

.user-details-profile-pic-container {
  margin-bottom: 10px;
}

.user-details-upload-pic-container {
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
}

.user-details-upload-pic-btn-container {
  margin-top: 10px;
}

.user-details-fields-width {
  width: 33%;
}

.user-details-pwdFields-width {
  width: 30%;
}

.user-details-updtProfile-btn-div {
  clear: both;
  margin-top: 10px;
}

//----- User Details Component End --------------------------------------------------------


//----- Avatar Component Start ------------------------------------------------------------
.avatarIcon {
  border-radius: 50%;
  color: white;
  background-color: darkgray;
  vertical-align: middle;
}
//----- Avatar Component End --------------------------------------------------------------


//----- Users Menu Component Start --------------------------------------------------------

.impersonating-container {
  float: left;
  margin-top: 5px;
}

.user-menu-button-div {
  float: right;
}

.user-menu-content {
  max-height: 33vh;
}

.user-profile-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
}

.user-menu-avatarIcon {
  border-radius: 50%;
  color: white;
  background-color: darkgray;
  vertical-align: middle;
  width: 27px;
  height: 27px;
  margin-right: 10px
}

.orgAvatarIcon {
  position: absolute !important;
  top: 0px;
  left: 37px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: green;
  background: white;
  height: 15px !important;
  width: 15px !important;
  font-size: 12px !important;
  z-index: 100;
}

.orgSelected {
  background-color: #cce5ff !important;
}

//----- Users Menu Component End ----------------------------------------------------------

//----- Users Component Start -------------------------------------------------------------

table {
  width: 100%;
}

tr.users-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.users-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.users-element-row td {
  border-bottom: 1px solid #ccc !important;
}

.users-header-content {
  display: flex !important;
  align-items: center !important;
  margin: 5px 0 !important;
}

//----- Users Component End ---------------------------------------------------------------

//----- Verify Component Start ------------------------------------------------------------

.verify-main-div {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}

.verify-card {
  margin: 100px auto;
  padding: 10px;
}

.reset-password-form {
  padding: 20px;
}

.reset-password-card {
  margin-top: 70px;
}

.reveal {
  cursor: pointer;
}

//----- Verify Component End --------------------------------------------------------------

.default {

  .mat-button-toggle-checked {
    background-color: $default-accent;
  }

  .mat-sidenav {
    background: $default-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.alternative {

  .mat-button-toggle-checked {
    background-color: $alternate-primary;
  }

  .mat-sidenav {
    background: $alternate-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.purple {
  background-color: lightgray;

  .mat-button-toggle-checked {
    background-color: $purple-primary;
  }

  .mat-sidenav {
    background: $purple-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.pink {
  background-color: lightcyan;

  .mat-button-toggle-checked {
    background-color: $pink-primary;
  }

  .mat-sidenav {
    background: $pink-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.orange {
  background-color: aqua;

  .mat-button-toggle-checked {
    background-color: $deep-orange-primary;
  }

  .mat-sidenav {
    background: $deep-orange-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.cyan {
  background-color: #e91e63;

  .mat-button-toggle-checked {
    background-color: $cyan-primary;
  }

  .mat-sidenav {
    background: $cyan-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }
}

.deepPurpleAmber {

  .theme-toolbar {
    background-color: $deepPurple-amber-accent;
  }

  .mat-button-toggle-checked {
    background-color: $deepPurple-amber-primary;
  }

  .mat-sidenav {
    background: $deepPurple-amber-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    color: #ffffff;
    background-color: $deepPurple-amber-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $deepPurple-amber-primary !important;
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $deepPurple-amber-primary !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $deepPurple-amber-primary !important;
  }
  /* Radio button theme colour */
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    /*opacity: 0.5 !important;*/ /*click effect color change*/
    background-color: $deepPurple-amber-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $deepPurple-amber-primary !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $deepPurple-amber-primary !important; /*outer ring color change*/
  }
}

.darkDefault {
  background-color: $pink-bluegrey-accent;

  .theme-toolbar {
    background-color: $pink-bluegrey-accent;
  }

  .mat-button-toggle-checked {
    background-color: $pink-bluegrey-primary;
  }

  .mat-sidenav {
    background: $pink-bluegrey-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    color: #ffffff;
    background-color: $pink-bluegrey-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $pink-bluegrey-primary !important;
  }
  /* Radio button theme colour */
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    /*opacity: 0.5 !important;*/ /*click effect color change*/
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $pink-bluegrey-primary !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $pink-bluegrey-primary !important; /*outer ring color change*/
  }
  /* Textarea theme color*/
}

.indigoPink {

  .theme-toolbar {
    background-color: $indigo-pink-accent;
  }

  .mat-button-toggle-checked {
    background-color: $indigo-pink-primary;
  }

  .mat-sidenav {
    background: $indigo-pink-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    color: #ffffff;
    background-color: $indigo-pink-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $indigo-pink-primary !important;
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $indigo-pink-primary !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $indigo-pink-primary !important;
  }
  /* Radio button theme colour */
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    /*opacity: 0.5 !important;*/ /*click effect color change*/
    background-color: $indigo-pink-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $indigo-pink-primary !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $indigo-pink-primary !important; /*outer ring color change*/
  }
  /* Textarea theme color*/
}

.pinkBlueGrey {
  background-color: $pink-bluegrey-accent;

  .theme-toolbar {
    background-color: $pink-bluegrey-accent;
  }

  .mat-button-toggle-checked {
    background-color: $pink-bluegrey-primary;
  }

  .mat-sidenav {
    background: $pink-bluegrey-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    color: #ffffff;
    background-color: $pink-bluegrey-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $pink-bluegrey-primary !important;
  }
  /* Radio button theme colour */
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    /*opacity: 0.5 !important;*/ /*click effect color change*/
    background-color: $pink-bluegrey-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $pink-bluegrey-primary !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $pink-bluegrey-primary !important; /*outer ring color change*/
  }
  /* Textarea theme color*/
}

.greenPurple {
  background-color: $green-purple-accent;

  .theme-toolbar {
    background-color: $green-purple-accent;
  }

  .mat-button-toggle-checked {
    background-color: $green-purple-primary;
  }

  .mat-sidenav {
    background: $green-purple-accent;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: #ffffff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    color: #ffffff;
    background-color: $green-purple-primary;
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $green-purple-primary !important;
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: $green-purple-primary !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $green-purple-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    /*opacity: 0.5 !important;*/ /*click effect color change*/
    background-color: $green-purple-primary !important;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $green-purple-primary !important; /*inner circle color change*/
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $green-purple-primary !important; /*outer ring color change*/
  }

  .mdc-form-field {
    color: #ffffff;
  }
}
