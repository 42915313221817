@use '@angular/material' as mat;

@include mat.core();

$first-custom-typography: mat.define-typography-config(
  $font-family: "'Google Sans', Roboto 'Helvetica Neue', sans-serif",
  $headline-1: mat.define-typography-level(112px, 112px, 300,  $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400,  $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400,  $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $subtitle-1: mat.define-typography-level(14px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400),
  $body-1: mat.define-typography-level(12px, 20px, 400),
  $body-2: mat.define-typography-level(12px, 24px, 500),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(12px, 14px, 500),
);

$my-theme-primary-color: ( 50 : #e1e4eb, 100 : #b5bcce, 200 : #8490ae, 300 : #53648d, 400 : #2e4274, 500 : #09215c, 600 : #081d54, 700 : #06184a, 800 : #051441, 900 : #020b30, A100 : #697bff, A200 : #364eff, A400 : #0321ff, A700 : #001ce8, contrast: ( 50 :#000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );
$my-theme-secondary-color: ( 50 : #f6f8fc, 100 : #e9eef8, 200 : #dae3f3, 300 : #cbd8ee, 400 : #62a8ff, 500 : #b4c7e7, 600 : #adc1e4, 700 : #a4bae0, 800 : #9cb3dd, 900 : #8ca6d7, A100 : #ffffff, A200 : #ffffff, A400 : #ffffff, A700 : #ebf2ff, contrast: ( 50 :#ffffff, 100 : #ffffff, 200 : #ffffff, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );

$my-theme-accent-color: (50 : #ecf5ff, 100 : #1fca5d, 200 : #b1d4ff, 300 : #91c2ff, 400 : #7ab5ff, 500 : #62a8ff, 600 : #5aa0ff, 700 : #5097ff, 800 : #468dff, 900 : #347dff, A100 : #ffffff, A200 : #ffffff, A400 : #dfe9ff, A700 : #c5d9ff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #000000, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #000000, A700 : #000000, ) );

$my-theme-primary: mat.define-palette($my-theme-primary-color, 400);
$my-theme-accent: mat.define-palette($my-theme-accent-color, 500);
$my-theme-progress: mat.define-palette($my-theme-accent-color, 100);

$my-theme: mat.define-light-theme((
    color: (primary: $my-theme-primary, accent: $my-theme-accent ),
    typography: $first-custom-typography,
    density: 0 ));

$my-progress-theme: mat.define-light-theme(( color: (primary: $my-theme-progress, accent: $my-theme-accent ), typography: $first-custom-typography, density: 0 ));

$my-condensed-theme: mat.define-light-theme(( color: ( primary: $my-theme-primary, accent: $my-theme-accent ), typography: $first-custom-typography, density: -2));

$my-condensed-theme2: mat.define-light-theme(( color: ( primary: $my-theme-primary, accent: $my-theme-accent ), typography: $first-custom-typography, density: -5));

$default-primary: mat.get-color-from-palette($my-theme-primary);
$default-accent: mat.get-color-from-palette($my-theme-accent);

.mat-mdc-raised-button {
    color: white !important;
}

.mat-mdc-flat-button {
    color: white !important;
}

.mat-mdc-form-field-error {
  font-size: 0.75em;
}
